<template>
  <!-- 扫码配置页面 -->
  <div class="scanconfig_body">
    <div class="scanconfig_top"></div>
    <div class="scanconfig_view">
      <el-form
        :model="form"
        ref="form"
        label-position="top"
        label-width="80px"
        size="small"
        style="padding:0 20px"
      >
        <el-form-item label="选择酒店">
          <el-select v-model="form.groupId" placeholder="请选择酒店" style="width:100%">
            <el-option
              v-for="item in options"
              :key="item.groupId"
              :label="item.groupName"
              :value="item.groupId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间号">
          <el-input v-model="form.roomId" placeholder="请输入房间号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-divider content-position="center">剩余授权0台</el-divider>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width:100%" round size="medium">立即创建</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import {getGroupListApi} from '../api/data'
export default {
  data() {
    return {
      options: [],
      form: {
        groupId: "",
        roomId: ""
      }
    };
  },
  created(){
    this.initLoad();
  },
  methods:{
    initLoad(){
      getGroupListApi().then(res=>{
        this.options=res.data;
      })
    }
  }
};
</script>
<style lang='scss'>
.scanconfig_body {
  background: url("../assets/external/scan_bg.png") center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
}
.scanconfig_top {
  height: 200px;
}
.scanconfig_view {
  width: 100%;
  margin: auto;
}
</style>